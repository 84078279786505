import { graphql, HeadFC, PageProps } from "gatsby";
import React from "react";
import { BaseStyles } from "theme-ui";
import Global from "../components/global";
import { Header } from "../components/header";
import Main from "../components/main";
import Seo from "../components/seo";
import Wrapper from "../components/wrapper";

export const Head: HeadFC<Queries.BlogPostQuery> = ({ data }) => {
  const post = data.markdownRemark;
  const siteTitle = post?.frontmatter?.overrideSiteTitle || "郷土芸能大会等の一覧 (Beta)"; // TODO: read from site metadata?

  return <Seo title={post?.frontmatter?.title} siteTitleTemplate={`%s – ${siteTitle}`} article={true} />;
};

const BlogPost = ({ data }: PageProps<Queries.BlogPostQuery>) => {
  const post = data.markdownRemark;
  const siteTitle = post?.frontmatter?.overrideSiteTitle || "郷土芸能大会等の一覧 (Beta)"; // TODO: read from site metadata?

  return (
    <>
      <Global />
      <Header siteTitle={siteTitle} />
      <Main>
        <Wrapper>
          <BaseStyles>
            <h1>{post?.frontmatter?.title}</h1>
            <style>
              {"\
@media screen and (min-width: 601px) {\
  .summarytext {\
    margin: 2.0rem;\
    padding: 1.0rem;\
    border: 1px solid;\
    width: 600px;\
  }\
  .flexbox {\
    display: flex;\
    flex-wrap: wrap;\
    justify-content: center;\
    justify-content: center;\
    align-items: center;\
    margin-left: auto;\
    margin-right: auto;\
  }\
  .flitem {\
    width: 405px;\
    margin: 0 0.5rem;\
  }\
  .flitemwide {\
    width: 540px;\
    margin: 0 0.5rem;\
  }\
  .flitemsquare {\
    width: 303.75px;\
    margin: 0 0.5rem;\
  }\
  .flitemnarrowsquare {\
    width: 270px;\
    margin: 0 0.5rem;\
  }\
  .flitemportrait {\
    width: 227.81px;\
    margin: 0 0.5rem;\
  }\
}\
\
@media screen and (max-width: 600px) {\
  .summarytext {\
    margin: 2.0rem 0.5rem 2.0rem 0.5rem;\
    padding: 1.0rem;\
    border: 1px solid;\
  }\
  .flexbox {\
    display: flex;\
    flex-wrap: wrap;\
    justify-content: center;\
    align-items: center;\
    margin-left: auto;\
    margin-right: auto;\
  }\
  .flitem {\
    width: 100%;\
  }\
  .flitemwide {\
    width: 100%;\
  }\
  .flitemsquare {\
    width: 75%;\
  }\
  .flitemnarrowsquare {\
    width: 66.67%;\
  }\
  .flitemportrait {\
    width: 56.25%;\
  }\
}\
"}
            </style>
            <div dangerouslySetInnerHTML={{ __html: post?.html ?? "" }} />
          </BaseStyles>
        </Wrapper>
      </Main>
    </>
  );
};

export default BlogPost;

export const query = graphql`
  query BlogPost($slug: String!) {
    markdownRemark(fields: { slug: { eq: $slug } }) {
      html
      frontmatter {
        title
        overrideSiteTitle
      }
    }
  }
`;
